export const countries = [
  {
      name: "Afganistan",
      code: "Afganistan"
  },
  {
      name: "Almanya",
      code: "Almanya"
  },
  {
      name: "Amerika Birleşik Devletleri",
      code: "Amerika Birleşik Devletleri"
  },
  {
      name: "Amerikan Samoa",
      code: "Amerikan Samoa"
  },
  {
      name: "Andorra",
      code: "Andorra"
  },
  {
      name: "Angola",
      code: "Angola"
  },
  {
      name: "Anguilla, İngiltere",
      code: "Anguilla, İngiltere"
  },
  {
      name: "Antigua ve Barbuda",
      code: "Antigua ve Barbuda"
  },
  {
      name: "Arjantin",
      code: "Arjantin"
  },
  {
      name: "Arnavutluk",
      code: "Arnavutluk"
  },
  {
      name: "Aruba, Hollanda",
      code: "Aruba, Hollanda"
  },
  {
      name: "Avustralya",
      code: "Avustralya"
  },
  {
      name: "Avusturya",
      code: "Avusturya"
  },
  {
      name: "Azerbaycan",
      code: "Azerbaycan"
  },
  {
      name: "Bahama Adaları",
      code: "Bahama Adaları"
  },
  {
      name: "Bahreyn",
      code: "Bahreyn"
  },
  {
      name: "Bangladeş",
      code: "Bangladeş"
  },
  {
      name: "Barbados",
      code: "Barbados"
  },
  {
      name: "Belçika",
      code: "Belçika"
  },
  {
      name: "Belize",
      code: "Belize"
  },
  {
      name: "Benin",
      code: "Benin"
  },
  {
      name: "Bermuda, İngiltere",
      code: "Bermuda, İngiltere"
  },
  {
      name: "Beyaz Rusya",
      code: "Beyaz Rusya"
  },
  {
      name: "Bhutan",
      code: "Bhutan"
  },
  {
      name: "Birleşik Arap Emirlikleri",
      code: "Birleşik Arap Emirlikleri"
  },
  {
      name: "Birmanya (Myanmar)",
      code: "Birmanya (Myanmar)"
  },
  {
      name: "Bolivya",
      code: "Bolivya"
  },
  {
      name: "Bosna Hersek",
      code: "Bosna Hersek"
  },
  {
      name: "Botswana",
      code: "Botswana"
  },
  {
      name: "Brezilya",
      code: "Brezilya"
  },
  {
      name: "Brunei",
      code: "Brunei"
  },
  {
      name: "Bulgaristan",
      code: "Bulgaristan"
  },
  {
      name: "Burkina Faso",
      code: "Burkina Faso"
  },
  {
      name: "Burundi",
      code: "Burundi"
  },
  {
      name: "Çad",
      code: "Çad"
  },
  {
      name: "Cape Verde",
      code: "Cape Verde"
  },
  {
      name: "Cayman Adaları, İngiltere",
      code: "Cayman Adaları, İngiltere"
  },
  {
      name: "Çek Cumhuriyeti",
      code: "Çek Cumhuriyeti"
  },
  {
      name: "Cezayir",
      code: "Cezayir"
  },
  {
      name: "Christmas Adası , Avusturalya",
      code: "Christmas Adası , Avusturalya"
  },
  {
      name: "Cibuti",
      code: "Cibuti"
  },
  {
      name: "Çin",
      code: "Çin"
  },
  {
      name: "Danimarka",
      code: "Danimarka"
  },
  {
      name: "Doğu Timor",
      code: "Doğu Timor"
  },
  {
      name: "Dominik Cumhuriyeti",
      code: "Dominik Cumhuriyeti"
  },
  {
      name: "Dominika",
      code: "Dominika"
  },
  {
      name: "Ekvator Ginesi",
      code: "Ekvator Ginesi"
  },
  {
      name: "Ekvator",
      code: "Ekvator"
  },
  {
      name: "El Salvador",
      code: "El Salvador"
  },
  {
      name: "Endonezya",
      code: "Endonezya"
  },
  {
      name: "Eritre",
      code: "Eritre"
  },
  {
      name: "Ermenistan",
      code: "Ermenistan"
  },
  {
      name: "Estonya",
      code: "Estonya"
  },
  {
      name: "Etiyopya",
      code: "Etiyopya"
  },
  {
      name: "Fas",
      code: "Fas"
  },
  {
      name: "Fiji",
      code: "Fiji"
  },
  {
      name: "Fildişi Sahili",
      code: "Fildişi Sahili"
  },
  {
      name: "Filipinler",
      code: "Filipinler"
  },
  {
      name: "Filistin",
      code: "Filistin"
  },
  {
      name: "Finlandiya",
      code: "Finlandiya"
  },
  {
      name: "Folkland Adaları, İngiltere",
      code: "Folkland Adaları, İngiltere"
  },
  {
      name: "Fransa",
      code: "Fransa"
  },
  {
      name: "Fransız Güney Eyaletleri (Kerguelen Adaları)",
      code: "Fransız Güney Eyaletleri (Kerguelen Adaları)"
  },
  {
      name: "Fransız Guyanası",
      code: "Fransız Guyanası"
  },
  {
      name: "Fransız Polinezyası",
      code: "Fransız Polinezyası"
  },
  {
      name: "Gabon",
      code: "Gabon"
  },
  {
      name: "Galler",
      code: "Galler"
  },
  {
      name: "Gambiya",
      code: "Gambiya"
  },
  {
      name: "Gana",
      code: "Gana"
  },
  {
      name: "Gaza Strip",
      code: "Gaza Strip"
  },
  {
      name: "Gibraltar, İngiltere",
      code: "Gibraltar, İngiltere"
  },
  {
      name: "Gine",
      code: "Gine"
  },
  {
      name: "Gine-Bissau",
      code: "Gine-Bissau"
  },
  {
      name: "Grenada",
      code: "Grenada"
  },
  {
      name: "Grönland",
      code: "Grönland"
  },
  {
      name: "Guadalup, Fransa",
      code: "Guadalup, Fransa"
  },
  {
      name: "Guam, Amerika",
      code: "Guam, Amerika"
  },
  {
      name: "Guatemala",
      code: "Guatemala"
  },
  {
      name: "Güney Afrika",
      code: "Güney Afrika"
  },
  {
      name: "Güney Georgia ve Güney Sandviç Adaları, İngiltere",
      code: "Güney Georgia ve Güney Sandviç Adaları, İngiltere"
  },
  {
      name: "Güney Kıbrıs Rum Yönetimi",
      code: "Güney Kıbrıs Rum Yönetimi"
  },
  {
      name: "Güney Kore",
      code: "Güney Kore"
  },
  {
      name: "Gürcistan H\r\n",
      code: "Gürcistan H\r\n"
  },
  {
      name: "Guyana",
      code: "Guyana"
  },
  {
      name: "Haiti",
      code: "Haiti"
  },
  {
      name: "Havai Adaları",
      code: "Havai Adaları"
  },
  {
      name: "Hindistan",
      code: "Hindistan"
  },
  {
      name: "Hırvatistan",
      code: "Hırvatistan"
  },
  {
      name: "Hollanda Antilleri",
      code: "Hollanda Antilleri"
  },
  {
      name: "Hollanda",
      code: "Hollanda"
  },
  {
      name: "Honduras",
      code: "Honduras"
  },
  {
      name: "İngiltere",
      code: "İngiltere"
  },
  {
      name: "Irak",
      code: "Irak"
  },
  {
      name: "İran",
      code: "İran"
  },
  {
      name: "İrlanda",
      code: "İrlanda"
  },
  {
      name: "İskoçya",
      code: "İskoçya"
  },
  {
      name: "İspanya",
      code: "İspanya"
  },
  {
      name: "İsrail",
      code: "İsrail"
  },
  {
      name: "İsveç",
      code: "İsveç"
  },
  {
      name: "İsviçre",
      code: "İsviçre"
  },
  {
      name: "İtalya",
      code: "İtalya"
  },
  {
      name: "İzlanda",
      code: "İzlanda"
  },
  {
      name: "Jamaika",
      code: "Jamaika"
  },
  {
      name: "Japonya",
      code: "Japonya"
  },
  {
      name: "Johnston Atoll, Amerika",
      code: "Johnston Atoll, Amerika"
  },
  {
      name: "K.K.T.C.",
      code: "K.K.T.C."
  },
  {
      name: "Kamboçya",
      code: "Kamboçya"
  },
  {
      name: "Kamerun",
      code: "Kamerun"
  },
  {
      name: "Kanada",
      code: "Kanada"
  },
  {
      name: "Kanarya Adaları",
      code: "Kanarya Adaları"
  },
  {
      name: "Karadağ",
      code: "Karadağ"
  },
  {
      name: "Katar",
      code: "Katar"
  },
  {
      name: "Kazakistan",
      code: "Kazakistan"
  },
  {
      name: "Kenya",
      code: "Kenya"
  },
  {
      name: "Kırgızistan",
      code: "Kırgızistan"
  },
  {
      name: "Kiribati",
      code: "Kiribati"
  },
  {
      name: "Kolombiya",
      code: "Kolombiya"
  },
  {
      name: "Komorlar",
      code: "Komorlar"
  },
  {
      name: "Kongo Demokratik Cumhuriyeti",
      code: "Kongo Demokratik Cumhuriyeti"
  },
  {
      name: "Kongo",
      code: "Kongo"
  },
  {
      name: "Kosova",
      code: "Kosova"
  },
  {
      name: "Kosta Rika",
      code: "Kosta Rika"
  },
  {
      name: "Küba",
      code: "Küba"
  },
  {
      name: "Kuveyt",
      code: "Kuveyt"
  },
  {
      name: "Kuzey İrlanda",
      code: "Kuzey İrlanda"
  },
  {
      name: "Kuzey Kore",
      code: "Kuzey Kore"
  },
  {
      name: "Kuzey Maryana Adaları",
      code: "Kuzey Maryana Adaları"
  },
  {
      name: "Laos",
      code: "Laos"
  },
  {
      name: "Lesotho",
      code: "Lesotho"
  },
  {
      name: "Letonya",
      code: "Letonya"
  },
  {
      name: "Liberya",
      code: "Liberya"
  },
  {
      name: "Libya",
      code: "Libya"
  },
  {
      name: "Liechtenstein",
      code: "Liechtenstein"
  },
  {
      name: "Litvanya",
      code: "Litvanya"
  },
  {
      name: "Lübnan",
      code: "Lübnan"
  },
  {
      name: "Lüksemburg",
      code: "Lüksemburg"
  },
  {
      name: "Macaristan",
      code: "Macaristan"
  },
  {
      name: "Madagaskar",
      code: "Madagaskar"
  },
  {
      name: "Makau (Makao)",
      code: "Makau (Makao)"
  },
  {
      name: "Makedonya",
      code: "Makedonya"
  },
  {
      name: "Malavi",
      code: "Malavi"
  },
  {
      name: "Maldiv Adaları",
      code: "Maldiv Adaları"
  },
  {
      name: "Malezya",
      code: "Malezya"
  },
  {
      name: "Mali",
      code: "Mali"
  },
  {
      name: "Malta",
      code: "Malta"
  },
  {
      name: "Marşal Adaları",
      code: "Marşal Adaları"
  },
  {
      name: "Martinik, Fransa",
      code: "Martinik, Fransa"
  },
  {
      name: "Mauritius",
      code: "Mauritius"
  },
  {
      name: "Mayotte, Fransa",
      code: "Mayotte, Fransa"
  },
  {
      name: "Meksika",
      code: "Meksika"
  },
  {
      name: "Midway Adaları, Amerika",
      code: "Midway Adaları, Amerika"
  },
  {
      name: "Mikronezya",
      code: "Mikronezya"
  },
  {
      name: "Mısır",
      code: "Mısır"
  },
  {
      name: "Moğolistan",
      code: "Moğolistan"
  },
  {
      name: "Moldavya",
      code: "Moldavya"
  },
  {
      name: "Monako",
      code: "Monako"
  },
  {
      name: "Montserrat",
      code: "Montserrat"
  },
  {
      name: "Moritanya",
      code: "Moritanya"
  },
  {
      name: "Mozambik",
      code: "Mozambik"
  },
  {
      name: "Namibia",
      code: "Namibia"
  },
  {
      name: "Nauru",
      code: "Nauru"
  },
  {
      name: "Nepal",
      code: "Nepal"
  },
  {
      name: "Nijer",
      code: "Nijer"
  },
  {
      name: "Nijerya",
      code: "Nijerya"
  },
  {
      name: "Nikaragua",
      code: "Nikaragua"
  },
  {
      name: "Niue, Yeni Zelanda",
      code: "Niue, Yeni Zelanda"
  },
  {
      name: "Norveç O\r\n",
      code: "Norveç O\r\n"
  },
  {
      name: "Orta Afrika Cumhuriyeti",
      code: "Orta Afrika Cumhuriyeti"
  },
  {
      name: "Özbekistan",
      code: "Özbekistan"
  },
  {
      name: "Pakistan",
      code: "Pakistan"
  },
  {
      name: "Palau Adaları",
      code: "Palau Adaları"
  },
  {
      name: "Palmyra Atoll, Amerika",
      code: "Palmyra Atoll, Amerika"
  },
  {
      name: "Panama",
      code: "Panama"
  },
  {
      name: "Papua Yeni Gine",
      code: "Papua Yeni Gine"
  },
  {
      name: "Paraguay",
      code: "Paraguay"
  },
  {
      name: "Peru",
      code: "Peru"
  },
  {
      name: "Polonya",
      code: "Polonya"
  },
  {
      name: "Portekiz",
      code: "Portekiz"
  },
  {
      name: "Porto Riko, Amerika",
      code: "Porto Riko, Amerika"
  },
  {
      name: "Reunion, Fransa",
      code: "Reunion, Fransa"
  },
  {
      name: "Romanya",
      code: "Romanya"
  },
  {
      name: "Ruanda",
      code: "Ruanda"
  },
  {
      name: "Rusya Federasyonu",
      code: "Rusya Federasyonu"
  },
  {
      name: "Saint Helena, İngiltere",
      code: "Saint Helena, İngiltere"
  },
  {
      name: "Saint Martin, Fransa",
      code: "Saint Martin, Fransa"
  },
  {
      name: "Saint Pierre ve Miquelon, Fransa",
      code: "Saint Pierre ve Miquelon, Fransa"
  },
  {
      name: "Samoa",
      code: "Samoa"
  },
  {
      name: "San Marino",
      code: "San Marino"
  },
  {
      name: "Santa Kitts ve Nevis",
      code: "Santa Kitts ve Nevis"
  },
  {
      name: "Santa Lucia",
      code: "Santa Lucia"
  },
  {
      name: "Santa Vincent ve Grenadinler",
      code: "Santa Vincent ve Grenadinler"
  },
  {
      name: "Sao Tome ve Principe",
      code: "Sao Tome ve Principe"
  },
  {
      name: "Senegal",
      code: "Senegal"
  },
  {
      name: "Seyşeller",
      code: "Seyşeller"
  },
  {
      name: "Sierra Leone",
      code: "Sierra Leone"
  },
  {
      name: "Şili",
      code: "Şili"
  },
  {
      name: "Singapur",
      code: "Singapur"
  },
  {
      name: "Sırbistan",
      code: "Sırbistan"
  },
  {
      name: "Slovakya",
      code: "Slovakya"
  },
  {
      name: "Slovenya",
      code: "Slovenya"
  },
  {
      name: "Solomon Adaları",
      code: "Solomon Adaları"
  },
  {
      name: "Somali",
      code: "Somali"
  },
  {
      name: "Sri Lanka",
      code: "Sri Lanka"
  },
  {
      name: "Sudan",
      code: "Sudan"
  },
  {
      name: "Surinam",
      code: "Surinam"
  },
  {
      name: "Suriye",
      code: "Suriye"
  },
  {
      name: "Suudi Arabistan",
      code: "Suudi Arabistan"
  },
  {
      name: "Svalbard, Norveç",
      code: "Svalbard, Norveç"
  },
  {
      name: "Svaziland",
      code: "Svaziland"
  },
  {
      name: "Tacikistan",
      code: "Tacikistan"
  },
  {
      name: "Tanzanya",
      code: "Tanzanya"
  },
  {
      name: "Tayland",
      code: "Tayland"
  },
  {
      name: "Tayvan",
      code: "Tayvan"
  },
  {
      name: "Togo",
      code: "Togo"
  },
  {
      name: "Tonga",
      code: "Tonga"
  },
  {
      name: "Trinidad ve Tobago",
      code: "Trinidad ve Tobago"
  },
  {
      name: "Tunus",
      code: "Tunus"
  },
  {
      name: "Türkiye",
      code: "Türkiye"
  },
  {
      name: "Türkmenistan",
      code: "Türkmenistan"
  },
  {
      name: "Turks ve Caicos Adaları, İngiltere",
      code: "Turks ve Caicos Adaları, İngiltere"
  },
  {
      name: "Tuvalu",
      code: "Tuvalu"
  },
  {
      name: "Uganda",
      code: "Uganda"
  },
  {
      name: "Ukrayna",
      code: "Ukrayna"
  },
  {
      name: "Umman",
      code: "Umman"
  },
  {
      name: "Ürdün",
      code: "Ürdün"
  },
  {
      name: "Uruguay",
      code: "Uruguay"
  },
  {
      name: "Vallis ve Futuna, Fransa",
      code: "Vallis ve Futuna, Fransa"
  },
  {
      name: "Vanuatu",
      code: "Vanuatu"
  },
  {
      name: "Venezuela",
      code: "Venezuela"
  },
  {
      name: "Vietnam",
      code: "Vietnam"
  },
  {
      name: "Virgin Adaları, Amerika",
      code: "Virgin Adaları, Amerika"
  },
  {
      name: "Virgin Adaları, İngiltere",
      code: "Virgin Adaları, İngiltere"
  },
  {
      name: "Wake Adaları, Amerika",
      code: "Wake Adaları, Amerika"
  },
  {
      name: "Yemen",
      code: "Yemen"
  },
  {
      name: "Yeni Kaledonya, Fransa",
      code: "Yeni Kaledonya, Fransa"
  },
  {
      name: "Yeni Zelanda",
      code: "Yeni Zelanda"
  },
  {
      name: "Yunanistan",
      code: "Yunanistan"
  },
  {
      name: "Zambiya",
      code: "Zambiya"
  },
  {
      name: "Zimbabve",
      code: "Zimbabve"
  }
];