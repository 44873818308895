import { ProjectActionTypes } from './project.types';

export const setLoggedIn = (isLoggedIn) => {
  return{
    type: ProjectActionTypes.SET_LOGGED_IN,
    value: isLoggedIn
  }
}

export const setPageLoading = (isPageLoading) => {
  return {
    type: ProjectActionTypes.SET_PAGE_LOADING,
    value: isPageLoading
  }
}

export const setWarningMessage = (payload) => {
  return {
    type: ProjectActionTypes.SET_WARNING_MESSAGE,
    payload: payload
  }
}

export const setLoading = (showLoading) => {
  return {
    type: ProjectActionTypes.SET_LOADING,
    showLoading: showLoading
  }
}


export const setMenu = (menu) => {
  return {
    type: ProjectActionTypes.SET_MENU,
    value: menu
  }
}

export const setCurrent = (current) => {
  return {
    type: ProjectActionTypes.SET_CURRENT,
    value: current
  }
}

export const setParent = (parent) => {
  return {
    type: ProjectActionTypes.SET_PARENT,
    value: parent
  }
}
export const setScroll = (scroll) => {
  return {
    type: ProjectActionTypes.SET_SCROLL,
    value: scroll
  }
}

export const setSearchQueryParams = (payload) => {
  return {
    type: ProjectActionTypes.SET_SEARCH_QUERY_PARAMS,
    payload: payload
  }
}

export const setLoginForm = (open) => {
  return {
    type: ProjectActionTypes.SET_LOGINFORM,
    value: open
  }
}

export const setLatestPosts = (posts) => {
  return {
    type: ProjectActionTypes.SET_POSTS,
    value: posts
  }
}

export const setExpertDetails = (payload) => {
  return dispatch => {
    dispatch({ type:ProjectActionTypes.SET_EXPERT_DETAILS, expertDetails: payload})
  }
}