export const experties = [
  "AVRUPA BİRLİĞİ",
  "BASIN YAYIN, MEDYA VE İLETİŞİM",
  "BİLİM VE TEKNOLOJİ",
  "ÇALIŞMA HAYATI (TAZMİNAT, SİGORTA VS)",
  "ÇATIŞMA ÇÖZÜMÜ",
  "ÇEVİRİBİLİM",
  "ÇEVRE, SÜRDÜREBİLİRLİK VE İKLİM DEĞİŞİKLİĞİ",
  "ÇOCUK GELİŞİMİ VE EĞİTİMİ",
  "ÇOCUK HAKLARI",
  "DEPREM VE AFET YÖNETİMİ",
  "DİN ve İNANÇ",
  "DIŞ POLİTİKA - Ortadoğu ve Kuzey Afrika",
  "DIŞ POLİTİKA- Avrupa - Avrupa Birliği- Avrupa Konseyi",
  "DIŞ POLİTİKA GENEL",
  "DIŞ POLİTİKA- KAFKASLAR",
  "EDEBİYAT",
  "EĞİTİM",
  "EKONOMİ VE FİNANS",
  "GIDA, BESLENME VE TARIM",
  "GİRİŞİMCİLİK VE İNNOVASYON",
  "GÖÇ VE MÜLTECİLER",
  "GÜVENLİK, SAVUNMA, TERÖRİZM",
  "HUKUK",
  "İNSAN HAKLARI",
  "İNSANİ YARDIM",
  "İŞ İDARESİ",
  "KADIN ÇALIŞMALARI",
  "KÜLTÜR VE SANAT",
  "MİMARLIK",
  "MÜHENDİSLİK",
  "MÜZİK",
  "PSİKOLOJİ",
  "SAĞLIK",
  "ŞEHİR PLANLAMA",
  "SİNEMA",
  "SİVİL TOPLUM",
  "SİYASET BİLİMİ VE KAMU YÖNETİMİ",
  "SOSYAL POLİTİKA",
  "SOSYOLOJİ",
  "SPOR, FITNESS",
  "SÜRDÜRÜLEBİLİRLİK",
  "TARİH",
  "TASARIM",
  "TIP VE SAĞLIK",
  "TOPLUM BİLİM",
  "TURİZM",
  "YAPAY ZEKA"
];

export const expertiesForSelect = [
  { "label":"Lütfen seçiniz","value":""},
  { "label":"AVRUPA BİRLİĞİ","value":"AVRUPA BİRLİĞİ"},
  { "label":"BASIN YAYIN, MEDYA VE İLETİŞİM","value":"BASIN YAYIN, MEDYA VE İLETİŞİM"},
  { "label":"BİLİM VE TEKNOLOJİ","value":"BİLİM VE TEKNOLOJİ"},
  { "label":"ÇALIŞMA HAYATI (TAZMİNAT, SİGORTA VS)","value":"ÇALIŞMA HAYATI (TAZMİNAT, SİGORTA VS)"},
  { "label":"ÇATIŞMA ÇÖZÜMÜ","value":"ÇATIŞMA ÇÖZÜMÜ"},
  { "label":"ÇEVİRİBİLİM","value":"ÇEVİRİBİLİM"},
  { "label":"ÇEVRE, SÜRDÜREBİLİRLİK VE İKLİM DEĞİŞİKLİĞİ","value":"ÇEVRE, SÜRDÜREBİLİRLİK VE İKLİM DEĞİŞİKLİĞİ"},
  { "label":"ÇOCUK GELİŞİMİ VE EĞİTİMİ","value":"ÇOCUK GELİŞİMİ VE EĞİTİMİ"},
  { "label":"ÇOCUK HAKLARI","value":"ÇOCUK HAKLARI"},
  { "label":"DEPREM VE AFET YÖNETİMİ","value":"DEPREM VE AFET YÖNETİMİ"},
  { "label":"DİN ve İNANÇ","value":"DİN ve İNANÇ"},
  { "label":"DIŞ POLİTİKA - Ortadoğu ve Kuzey Afrika","value":"DIŞ POLİTİKA - Ortadoğu ve Kuzey Afrika"},
  { "label":"DIŞ POLİTİKA- Avrupa - Avrupa Birliği- Avrupa Konseyi","value":"DIŞ POLİTİKA- Avrupa - Avrupa Birliği- Avrupa Konseyi"},
  { "label":"DIŞ POLİTİKA GENEL","value":"DIŞ POLİTİKA GENEL"},
  { "label":"DIŞ POLİTİKA- KAFKASLAR","value":"DIŞ POLİTİKA- KAFKASLAR"},
  { "label":"EDEBİYAT","value":"EDEBİYAT"},
  { "label":"EĞİTİM","value":"EĞİTİM"},
  { "label":"EKONOMİ VE FİNANS","value":"EKONOMİ VE FİNANS"},
  { "label":"GIDA, BESLENME VE TARIM","value":"GIDA, BESLENME VE TARIM"},
  { "label":"GİRİŞİMCİLİK VE İNNOVASYON","value":"GİRİŞİMCİLİK VE İNNOVASYON"},
  { "label":"GÖÇ VE MÜLTECİLER","value":"GÖÇ VE MÜLTECİLER"},
  { "label":"GÜVENLİK, SAVUNMA, TERÖRİZM","value":"GÜVENLİK, SAVUNMA, TERÖRİZM"},
  { "label":"HUKUK","value":"GÜVENLİK, SAVUNMA, TERÖRİZM"},
  { "label":"İNSAN HAKLARI","value":"İNSAN HAKLARI"},
  { "label":"İNSANİ YARDIM","value":"İNSANİ YARDIM"},
  { "label":"İŞ İDARESİ","value":"İŞ İDARESİ"},
  { "label":"KADIN ÇALIŞMALARI","value":"KADIN ÇALIŞMALARI"},
  { "label":"KÜLTÜR VE SANAT","value":"KÜLTÜR VE SANAT"},
  { "label":"MİMARLIK","value":"MİMARLIK"},
  { "label":"MÜHENDİSLİK","value":"MÜHENDİSLİK"},
  { "label":"MÜZİK","value":"MÜZİK"},
  { "label":"PSİKOLOJİ","value":"PSİKOLOJİ"},
  { "label":"SAĞLIK","value":"SAĞLIK"},
  { "label":"ŞEHİR PLANLAMA","value":"ŞEHİR PLANLAMA"},
  { "label":"SİNEMA","value":"SİNEMA"},
  { "label":"SİVİL TOPLUM","value":"SİVİL TOPLUM"},
  { "label":"SİYASET BİLİMİ VE KAMU YÖNETİMİ","value":"SİYASET BİLİMİ VE KAMU YÖNETİMİ"},
  { "label":"SOSYAL POLİTİKA","value":"SOSYAL POLİTİKA"},
  { "label":"SOSYOLOJİ","value":"SOSYOLOJİ"},
  { "label":"SPOR, FITNESS","value":"SPOR, FITNESS"},
  { "label":"SÜRDÜRÜLEBİLİRLİK","value":"SÜRDÜRÜLEBİLİRLİK"},
  { "label":"TARİH","value":"TARİH"},
  { "label":"TASARIM","value":"TASARIM"},
  { "label":"TIP VE SAĞLIK","value":"TIP VE SAĞLIK"},
  { "label":"TOPLUM BİLİM","value":"TOPLUM BİLİM"},
  { "label":"TURİZM","value":"TURİZM"},
  { "label":"YAPAY ZEKA", "value":"YAPAY ZEKA"}
]