import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import NavBarSub from '../navbarsub/navbarsub.component';
// get our fontawesome imports

import './headermedya.styles.scss';

class HeaderMedya extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  goTo = (event,url) => {
    event.preventDefault();
    return this.props.history.push('/'+url);    
  }

  componentDidMount(){

  }  

  render(){
    return(
      <React.Fragment>
        <NavBarSub />
        <header className={`topHeader ${this.props.currentPage === "baslangicmedya" ? "headerBaslangic" : ""}`}>
        <div className="headerContentWrapper">
          
          {
            this.props.currentPage !== "baslangicmedya" ?
            this.props.currentPage !== "successmedya" &&
              <React.Fragment>
              <h2 className="webSectionTitle">Medya Kayıt Formu</h2>   
              

              </React.Fragment>
              : <h2 className="webSectionTitle">Medya Kayıt Formu</h2>   
          } 
        </div>
      </header>      

      </React.Fragment>
    );
  }
}

export default withRouter(HeaderMedya);