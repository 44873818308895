import { DefinitionsActionTypes } from './definitions.types.js';


const INITIAL_STATE = {
  step1: {},
  step2: {},
  step3: {},
  step4: {},
  step5: {},
  picture: {},
  kayitformu: {},
  formChatOpenStatus:false

}

const definitionReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case DefinitionsActionTypes.SET_STEP1:
      return {
        ...state,
        step1: action.step1
      }

    case DefinitionsActionTypes.SET_STEP2:
      return{
        ...state,
        step2: action.step2
      }

    case DefinitionsActionTypes.SET_STEP3:
      return{
        ...state,
        step3: action.step3
      }      

    case DefinitionsActionTypes.SET_STEP4:
      return{
        ...state,
        step4: action.step4
      }      

    case DefinitionsActionTypes.SET_STEP5:
      return{
        ...state,
        step5: action.step5
      }      

    case DefinitionsActionTypes.SET_PICTURE:
      return{
        ...state,
        picture: action.picture
      }            

    case DefinitionsActionTypes.SET_KAYITFORMU:
      return {
        ...state,
        kayitformu: action.kayitformu
      }

    case DefinitionsActionTypes.SET_CHATFORM_STATUS:
      return {
        ...state,
        formChatOpenStatus: action.formChatOpenStatus
      }      

    default:
      return state;

  }
}

export default definitionReducer;