import React from 'react';
import './checkbox.styles.scss';

const CheckBox = ({name,checked,value,defaultChecked,readonly,onChange,children}) => (
  <label className="checkContainer">{children}
    <input type="checkbox" name={name} checked={checked} onChange={onChange} readOnly={readonly === true ? true : false} />
    <span className="checkmark">
      <svg aria-hidden="true" part="checked-indicator" className="checked-indicator checkdp" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.143 12.6697L15.235 4.5L16.8 5.90363L8.23812 15.7667L3.80005 11.2556L5.27591 9.7555L8.143 12.6697Z"></path>
      </svg>    
    </span>
  </label>  

  
);

export default CheckBox;