import { LoginActionTypes  } from './login.types';
import '../project/project.actions';
import axios from 'axios';

export const loginStart = (value) => {
  return{
    type: LoginActionTypes.LOGIN_START,
    loginStart: value
  }
}

export const loginSuccess = (loginData) => {
  return {
    type: LoginActionTypes.LOGIN_SUCCESS,
    loginData: loginData,
    User: loginData.data.data.data,
    error:null
  }
}
// will be merged later
export const loginFail = (loginData) => {
  return {
    type: LoginActionTypes.LOGIN_FAIL,
    loginData: loginData,
    error: loginData.data.data
  }
}

export const networkFail = (payload) => {
  return {
    type: LoginActionTypes.NETWORK_FAIL,
    payload: payload
  }
}


export const setLoginWarning = (payload) => {
  return {
    type: LoginActionTypes.SET_LOGIN_WARNING,
    payload: payload
  }
}


export const setLoading = (showLoading) => {
  return {
    type: LoginActionTypes.SET_LOADING,
    showLoading: showLoading
  }
}

export const setLoadingPopup = (showLoadingPopup) => {
  return {
    type: LoginActionTypes.SET_LOADING_POPUP,
    showLoadingPopup: showLoadingPopup
  }
}


export const userSignIn = (username, password) => {
  return async dispatch => {
    dispatch(loginStart(true));
    try{
      const fmData = new FormData();
      fmData.append("username", username);
      fmData.append("password", password);
      const config = {
        headers: { "content-type": "multipart/form-data" },
        crossDomain: true
      }
      const loginResult = await axios.post(`https://www.sensizolmazsessizolmaz.org/html/do_actionlogin.php`,
            fmData,
            config);
      if (loginResult.status === 200){
        if (loginResult.data.type === "success"){
           dispatch(loginSuccess(loginResult));
        }else{
          dispatch(loginFail(loginResult));
        }
      }else{
        dispatch(loginFail(loginResult));
      }      
    }catch(err){
      dispatch(networkFail('network'));
    }
  }
}

export const handleChange = (event) => {
  return {
    type: LoginActionTypes.LOGIN_INPUT_CHANGE,
    event: event
  }
}

export const logOut = () => {
  return {
    type: LoginActionTypes.SIGN_OUT
  }
}

export const userLogout = () => {
  return dispatch => {
    dispatch(logOut());
  }
}

// export const userLogout = () => {
//   return dispatch => {
//     dispatch(logOut());
//   }
// }


export const setFormLoginStatus = (payload) => {
  return dispatch => {
    dispatch({ type:LoginActionTypes.SET_LOGIN_FORM_STATUS, formLoginOpenStatus: payload})
  }
}


export const setExpertUrlBeforeLogin = (payload) => {
  return dispatch => {
    dispatch({ type:LoginActionTypes.SET_EXPERT_URL_BEFORE_LOGIN, experturl: payload})
  }
}

export const setChatMessageAfterLogin = (payload) => {
  return dispatch => {
    dispatch({ type:LoginActionTypes.SET_CHAT_MESSAGE_AFTER_LOGIN, chatMessageAfterLogin: payload})
  }
}
