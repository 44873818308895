export const LoginActionTypes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_INPUT_CHANGE: 'LOGIN_INPUT_CHANGE',
  LOGIN_SELECT_CHANGE: 'LOGIN_SELECT_CHANGE',
  LOGIN_REMEMBER_CHECKED:'LOGIN_REMEMBER_CHECKED',
  SET_LOGIN_WARNING: 'SET_LOGIN_WARNING',
  SET_LOADING: 'SET_LOADING',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_PAGE_LOADING: 'SET_PAGE_LOADING',
  NETWORK_FAIL: 'NETWORK_FAIL',
  SET_CAPTCHA: 'SET_CAPTCHA',
  SET_PASS: 'SET_PASS',
  SET_REPASS: 'SET_REPASS',
  SET_LOADING_POPUP: 'SET_LOADING_POPUP',
  SET_PROCESS_FINISHED: 'SET_PROCESS_FINISHED',
  SET_LOGIN_FORM_STATUS: 'SET_LOGIN_FORM_STATUS',
  SET_EXPERT_URL_BEFORE_LOGIN: 'SET_EXPERT_URL_BEFORE_LOGIN',
  SET_CHAT_MESSAGE_AFTER_LOGIN: 'SET_CHAT_MESSAGE_AFTER_LOGIN',

}
