import React from 'react';
import './header.styles.scss';
import NavBar from '../navbar/navbar.component';

class Header extends React.Component{
  render(){
    return(
      <React.Fragment>
        <NavBar menutype="smaller"  />
      </React.Fragment>
    );
  }
}

export default Header;